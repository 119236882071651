import React from 'react'

import styles from './style.module.scss'

import Image from 'gatsby-image'

import LogoImage from './Image'

import { StaticQuery, graphql } from 'gatsby'

import { FiFileText } from 'react-icons/fi'

function getAreasWithReports(data) {
    return data.allWordpressWpAreas.edges.filter(edge => {
        return edge.node.acf && edge.node.acf.research_pdf_file
    })
}
export default () => {
    return (
        <StaticQuery
            query={graphql`
                {
                    allWordpressWpAreas {
                        edges {
                            node {
                                name
                                slug
                                wordpress_id
                                acf {
                                    research_pdf_file {
                                        source_url
                                        title
                                    }
                                    area_image {
                                        localFile {
                                            childImageSharp {
                                                fluid(maxWidth: 900) {
                                                    ...GatsbyImageSharpFluid_withWebp
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={data => {
                const edges = getAreasWithReports(data)

                return (
                    <div className={styles.marketReports}>
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h1>Market reports</h1>
                                    <div className="row">
                                        {edges.map(edge => (
                                            <div
                                                key={edge.node.slug}
                                                className="col-xs-12 col-sm-4"
                                            >
                                                <a
                                                    href={
                                                        edge.node.acf
                                                            .research_pdf_file
                                                            .source_url
                                                    }
                                                    className={
                                                        styles.reportBlock
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Image
                                                        className={styles.image}
                                                        fluid={
                                                            edge.node.acf
                                                                .area_image
                                                                .localFile
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                    />
                                                    <h2>
                                                        <FiFileText />
                                                        <span>
                                                            {edge.node.acf.research_pdf_file.title.replace(
                                                                /-/g,
                                                                ' '
                                                            )}
                                                        </span>
                                                    </h2>
                                                </a>
                                            </div>
                                        ))}
                                    </div>

                                    <div className={styles.poweredBy}>
                                        <div className="row">
                                            <div className="col-xs-4 col-sm-2 ">
                                                <p>Powered by</p>
                                                <LogoImage />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}
