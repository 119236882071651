import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import MarketReportSections from '../components/market-report-sections'

import { get } from 'lodash-es'

export default ({ data }) => {
    let page = get(data, 'allWordpressPage.edges[0].node')

    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Market reports'
                }
            ]}
            marketTicker
        >
            <SEO
                title={get(page, 'yoast_meta.yoast_wpseo_title')}
                description={get(page, 'yoast_meta.yoast_wpseo_metadesc')}
            />
            <MarketReportSections />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "market-reports" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
